import request from '@/service/request'

//删除模板
export function deleteTemplate(data) {
  return request({
    url: '/externaladmin/systemService/expressFreightTemplate/delete',
    method: 'post',
    data
  })
}

//创建模板
export function createTemplate(data) {
  return request({
    url: '/externaladmin/systemService/expressFreightTemplate/complexCreate',
    method: 'post',
    data
  })
}

//创建模板
export function updateTemplate(data) {
  return request({
    url: '/externaladmin/systemService/expressFreightTemplate/complexUpdate',
    method: 'post',
    data
  })
}

//根据id获取模板
export function getTemplate(data) {
  return request({
    url: '/externaladmin/systemService/expressFreightTemplate/getById',
    method: 'post',
    data
  })
}

//批量创建模板
export function batchCreateTemplate(data) {
  return request({
    url: '/externaladmin/systemService/expressFreightTemplateConfig/complexBatchCreate',
    method: 'post',
    data
  })
}

//获取已创建的模板
export function getCreatedTemplate(data) {
  return request({
    url: '/externaladmin/systemService/expressFreightTemplateConfig/list',
    method: 'post',
    data
  })
}

//获取已创建的模板
export function getCreatedTemplateByCountryId(data) {
  return request({
    url: '/externaladmin/systemService/expressFreightTemplateConfig/complexList',
    method: 'post',
    data
  })
}

//删除已创建的模板
export function deleteCreatedTemplate(data) {
  return request({
    url: '/externaladmin/systemService/expressFreightTemplateConfig/delete',
    method: 'post',
    data
  })
}

//批量更改已创建的模板
export function bulkUpdate(data) {
  return request({
    url: '/externaladmin/systemService/expressFreightTemplateConfig/complexBatchUpdate',
    method: 'post',
    data
  })
}

//获取绑定的邮政列表
export function getRelatedMailList(data) {
  return request({
    url: '/externaladmin/systemService/expressFreightTemplate/getShippingMethodById',
    method: 'post',
    data
  })
}

//复制运费模板
export function copy(data) {
  return request({
    url: '/externaladmin/systemService/expressFreightTemplate/complexCopy',
    method: 'post',
    data
  })
}

//运费模板复杂保存一条数据记录
export function complexSave(data) {
  return request({
    url: '/externaladmin/systemService/expressFreightTemplate/complexSave',
    method: 'post',
    data
  })
}

//获取省列表
export function getProvinceList(data) {
  return request({
    url: '/externaladmin/systemService/administrativeArea/list',
    method: 'post',
    data
  })
}
